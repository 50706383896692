import React, { useState } from "react";
import { Button, message, Form } from "antd";
import { stopGrid, GridEndpointsEnum } from "../../api/api";
import { MarketPlace } from "../../../const";
import { useAuth } from "../../auth/AuthProvider";

export interface StopGridButtonProps {
  marketPlace: MarketPlace
}

// FIXME: this is a pretty dumb solution and without error handling
const marketPlaceToEndpoint = (marketPlace: MarketPlace) => {
  const convert = {
    COIN: GridEndpointsEnum.COIN,
    USDM: GridEndpointsEnum.USDM
  }
  return convert[marketPlace]
}
export const StopGridButton: React.FC<StopGridButtonProps> = ({ marketPlace }) => {
  const [loading, setLoading] = useState(false);
  let { user } = useAuth()
  const onClick = async () => {
    try {

      setLoading(true);
      const marketPlaceEndpoint = marketPlaceToEndpoint(marketPlace)
      const res = await stopGrid(marketPlaceEndpoint, { userId: user?.id.toString() });
      if (res.status != 200) {
        message.error(res.data);
        setLoading(false);
        return res
      }
      message.success("Grid stopped successfully");
      setLoading(false);
    } catch (err) {
      console.log(err.message || err.statusText);
      message.error(
        "Can't close grid becuase of internal error, please close grid manually"
      );

      setLoading(false);
    }
  };
  return (
    <>
      <Form.Item>
        <Button type="primary" danger onClick={onClick} loading={loading}>
          Stop Grid
        </Button>
      </Form.Item>
    </>
  );
};
