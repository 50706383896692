import React, { useState, useEffect } from "react";

import { CenterCard } from "../components/CenterCard";
import { StartGridTab } from "./components/StartGrid";
import { TabType } from "../../layout/TabView";
import { TableView } from "../../layout/TabView";
import { startCoinGrid, startUsdmGrid } from "../api/api";
import { MarketPlace } from "../../const";

export const StartGridPage: React.FC = () => {
  const coin = "Coin";
  const usdm = "Usdm";
  const contentObj = {
    [usdm]: <StartGridTab gridName={MarketPlace.Usdm} startGridReq={startUsdmGrid} />,
    [coin]: <StartGridTab gridName={MarketPlace.Coin} startGridReq={startCoinGrid} />,
  };
  const tabList: TabType = [
    { key: coin, tab: coin },
    { key: usdm, tab: usdm },
  ];
  return (
    <div>
      <TableView tabList={tabList} contentObj={contentObj} />
    </div>
  );
};
