import React from "react";
import { TableView, TabType } from "../../layout/TabView";
import { UpdateUserPage } from "./UpdateUserPage";
import { GetUserPage } from "./GetUserPage";

export const UserPage = () => {
    const updateUser = "updateUser";
    const getUser = "getUser";
    const contentObj = {
        [updateUser]: <UpdateUserPage />,
        [getUser]: <GetUserPage />,
    };
    const tabList: TabType = [
        { key: updateUser, tab: updateUser },
        { key: getUser, tab: getUser },
    ];

    return (
        <>
            <TableView tabList={tabList} contentObj={contentObj} />
        </>
    );
};
