import React, { createContext, useState, useEffect } from "react";
import { TableView } from "../../../layout/TabView";
import { getAllUsers } from "../../api/api";
import { AllUserTable } from "./AllUsersTable";
import { message } from "antd";


export const UserContext = createContext(null);

export const AllUserTab = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await getAllUsers();
    setLoading(false);
    if (res.status === 200) {
      const users = res.data;
      setAllUsers(users.users);
    } else {
      console.log(res, "error response");
      setError("can't get all users");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    message.error(error)
    return <div>Faild to load users</div>;
  }

  return (
    <UserContext.Provider value={{ fetchUsers }}>
      <AllUserTable users={allUsers} />
    </UserContext.Provider>
  );

};
