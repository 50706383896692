import React from "react";
import { TableView, TabType } from "../../layout/TabView";
import { AllUserTab } from "./components/AllUsersTab";
import { RegisterUserTab } from "./components/RegisterUserTab";

export const Adminpage = () => {
  const allUsers = "allUsers";
  const registerUsers = "registerUsers";
  const contentObj = {
    [allUsers]: <AllUserTab />,
    [registerUsers]: <RegisterUserTab />,
  };
  const tabList: TabType = [
    { key: allUsers, tab: allUsers },
    { key: registerUsers, tab: registerUsers },
  ];

  return (
    <>
      <TableView tabList={tabList} contentObj={contentObj} />
    </>
  );
};
