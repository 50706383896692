import React, { useState, useEffect } from "react";
import { getUser } from "../api/api";
import { message } from "antd";
import { GetUserCard } from "./components/GetUserCard";
import { User } from "../auth/authutils";

export const GetUserPage = () => {
    const [user, setUser] = useState<User>();
    const [error, setError] = useState<undefined | string>(undefined);
    const [loading, setLoading] = useState(false);

    const fetchUsers = async () => {
        setLoading(true);
        // FIXME: you can  get the sam data from the auth context, just fix the issue that the telegram channel is not being passed
        const res = await getUser();
        setLoading(false);
        if (res.status === 200) {
            const user = res.data.user;
            setUser(user);
        } else {
            console.log(res, "error response");
            setError("can't get all users");
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (loading) {
        return <div>loading</div>;
    }

    if (error) {
        message.error(error)
        return <div>Faild to load users</div>;
    }

    return (
        <GetUserCard user={user} />
    );

};
