import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { useAuth, getToken } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import { CenterCard } from "../components/CenterCard";
import { LoadingSkeleton } from "../../layout/LoadingSkeletorn";
import { AxiosError } from 'axios'

export const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const { login, validateBackend } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    if (token) {
      validateBackend(token).then((res) => {
        if (res) {
          navigate("/app");
          setLoadingPage(false);
        } else {
          console.log("found invalid token, please log in again");
          setLoadingPage(false);
        }
      });
    }
    setLoadingPage(false);
  }, []);

  const onLogin = async (values) => {
    setLoading(true);
    try {   
      const res = await login(values);
      if (res === false) {
        message.error(`Password or username is incorrect`);
        setLoading(false);
        return;
      }
      setLoading(false);
      navigate("/app");
    } catch (error) {
      message.error(`Password or username is incorrect`);
      setLoading(false);
      return;

    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {loadingPage ? (
        <LoadingSkeleton />
      ) : (
        <CenterCard>
          <Form
            form={form}
              onFinish={onLogin}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              label="Username"
              name="name"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Enter your username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </CenterCard>
      )}
    </>
  );
};

export default Login;
