// const URL = "http://172.28.1.2:8080/api";
// const URL = "http://130.61.52.50/api";
// console.log(process.env.REACT_APP_API_URL);
// const URL = process.env.REACT_APP_API_URL;
// export const URL = "http://localhost:8080/api";
// export const URL = "http://172.28.1.2:8080/api";
// fix that
const getUrl = () => process.env.REACT_APP_API_URL || 'http://localhost:8080/api'
export const URL = getUrl()
export enum MarketPlace {
    Coin = "COIN",
    Usdm = "USDM"
}




