import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { UserOutlined, HomeOutlined, PlaySquareOutlined } from "@ant-design/icons";

import { StopGridButton } from "../app/grid/components/StopGridButton";
import { getToken, useAuth } from "../app/auth/AuthProvider";
import { User, decodeJwt } from "../app/auth/authutils";
import { ItemType } from "antd/es/menu/hooks/useItems";

const uri_to_key: Record<string, string> = {
  "/": "1",
  "/userSpace": "2",
  "/allUsers": "3",
};

interface MenuItem {
  key: string;
  icon: JSX.Element;
  label: JSX.Element;
}

interface Menu {
  theme: string;
  mode: string;
  selectedKeys: string[];
  defaultSelectedKeys: string[];
  items: MenuItem[];
}

function generateMenu(user: User): ItemType[] {
  const menu: ItemType[] = [
    {
      key: "1",
      icon: <HomeOutlined />,
      label: <NavLink to="/app"> Start Grid </NavLink>,
    },
    {
      key: "2",
      icon: <UserOutlined />,
      label: <NavLink to="/userSpace">User Space </NavLink>,
    },
    {
      key: "3",
      icon: <PlaySquareOutlined />,
      label: <NavLink to="/reloadGrid"> Reload Grid </NavLink>,
    }
    ,
  ]

  if (user && user.isAdmin) {
    menu.push({
      key: "3",
      icon: <UserOutlined />,
      label: <NavLink to="/manageUsers">Manage Users </NavLink>,
    });
  }

  return menu;
}



export const PageLayout: React.FC = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const location = useLocation();
  const uri = location.pathname;
  let { user, getAccessToken } = useAuth()
  useEffect(() => {
    if (!user) {
      const res = getAccessToken().then((res) => {
        if (user) {
          user = res
        }
      })
    }
  }, [user])


  return (
    <Layout
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
      }}
    >
      <Sider breakpoint="lg" collapsedWidth="0">
        <div
          style={{
            height: "32px",
            margin: "16px",
            background: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <Menu
          theme="dark"
          mode="inline"
          // style={{ display: 'flex' }}
          items={generateMenu(user)}
          selectedKeys={[uri_to_key[uri]]}
          defaultSelectedKeys={[uri_to_key[uri]]}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <NavLink to="/app"> Start Grid </NavLink>
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />}>
            <NavLink to="/userSpace">Update User </NavLink>
          </Menu.Item>

          {user.isAdmin && (
            <Menu.Item key="3" icon={<UserOutlined />}>
              <NavLink to="/manageUsers">Manage Users </NavLink>
            </Menu.Item>

          )} 
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: "0 50",
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {" "}
        </Header>
        <Content>
          <Outlet />
          {/* <div style={{ padding: 24, minHeight: 360, background: '#fff' }}></div> */}
        </Content>
      </Layout>
    </Layout>
  );
};
