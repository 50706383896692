import React from "react";
import { Form, Input } from "antd";

const condMsg = (fieldName: string) => `Please add ${fieldName} if you have it`;

export const RegisterUserForm = () => {
  return (
    <>
      <Form.Item
        label="User Name"
        name="name"
        rules={[{ required: true, message: "Please add the user name" }]}
      >
        <Input placeholder="Enter the user name" type="text" />
      </Form.Item>

      <Form.Item label="Api Key" name="apiKey">
        <Input placeholder={condMsg("api key")} type="text" defaultValue="" />
      </Form.Item>
      <Form.Item label="Api Secret" name="apiSecret">
        <Input
          placeholder={condMsg("api secret")}
          type="text"
          defaultValue=""
        />
      </Form.Item>

      <Form.Item label="Telegram Channel" name="telegramChannel">
        <Input
          placeholder={condMsg("telegram channel")}
          type="text"
          defaultValue=""
        />
      </Form.Item>
    </>
  );
};
