import React from "react";
import { Routes, Route } from "react-router-dom";

import { Login } from "../app/auth/Login";
import { StartGridPage } from "../app/grid/StartGridPage";
import { UserPage } from "../app/user/UserPage";
import { ReloadGridPage } from "../app/grid/ReloadGridPage";
import { Adminpage } from "../app/admin/AdminPage";
import { ProtectedRoute, AdminRoute } from "../app/auth/ProtectedRoute";
import { PageLayout } from "../layout/SideNav";

export const Router = () => {

  return (
    <>
      <Routes>
        <Route index element={<Login />} />
        <Route element={<ProtectedRoute children={null} />}>
          <Route element={<PageLayout />}>
            <Route path="/app" element={<StartGridPage />} />
            <Route path="/reloadGrid" element={<ReloadGridPage />} />
            <Route path="/userSpace" element={<UserPage />} />
            <Route element={<AdminRoute children={null} />}>
              <Route path="/manageUsers" element={<Adminpage />} />
            </Route> 
          </Route>
        </Route>
      </Routes>
    </>
  );
};
