import React, { useState } from "react";
import { message } from "antd";
import { StartGridInput } from "../../api/api";
import { AxiosResponse } from "axios";
import { handleError } from "../../../utils";
import { GridForm } from "./GridForm"
import { StopGridButton } from "./StopGridButton";
import { MarketPlace } from "../../../const";
import { useAuth } from "../../auth/AuthProvider";

export interface StartGridValues {
    gridSize: number;
    upperBound: number;
    lowerBound: number;
    leverage: number;
    restartPercentage: number
    strategy: string
    closePosition: boolean
    userId: string
}

interface StartGridFormContainerProps {
    startGridReq: (gridData: StartGridInput) => Promise<AxiosResponse<any, any>>;
    gridName: MarketPlace;
    reloadGrid?: boolean;
}

export const StartGridTab: React.FunctionComponent<StartGridFormContainerProps> = ({
    startGridReq,
    gridName,
}) => {
    const [loading, setLoading] = useState(false);
    const [disableButtons, setDisableButtons] = useState(true);
    let { user } = useAuth()
    console.log(user, "the user?")
    const startGridCall = async (values: StartGridValues) => {
        setLoading(true);
        const data: StartGridInput = {
            gridSize: Number(values.gridSize),
            lowerBound: values.lowerBound && Number(values.lowerBound),
            upperBound: values.upperBound && Number(values.upperBound),
            leverage: values.leverage && Number(values.leverage),
            restartPercentage: values.restartPercentage && Number(values.restartPercentage),
            strategy: values.strategy,
            userId: user?.id.toString(),
            closePosition: values.closePosition
        };
        try {
            const res = await startGridReq(data);
            if (res.status === 200) {
                message.success(
                    "Starting grid, please look at the logs for information regarding the grid state"
                );
            } else {
                message.error(`Failed to start grid for unknown reason`);
            }
        } catch (error) {
            const errMsg = handleError(error.response?.data?.error);
            message.error(`Failed to start grid: ${errMsg}`);
        }
        setLoading(false);
    };

    return (
        <>

            <GridForm
                onSubmit={startGridCall}
                loading={loading}
                disableButtons={disableButtons}
                setDisableButtons={setDisableButtons}
                gridName={gridName}
            />

            <StopGridButton marketPlace={gridName} />
        </>
    );
};
