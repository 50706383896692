import React from "react";
// import TradingViewWidget from "./app/widget/Widget";

import { CrosshairMode, LineStyle } from "lightweight-charts";
import { AuthProvider } from "./app/auth/AuthProvider";
import { Router } from "./routes/Router";

// https://dev.to/onlyayep/how-i-build-crypto-tracker-chart-with-react-4k9h
// https://www.robinwieruch.de/react-router-authentication/

function App() {
  const [token, setToken] = React.useState(null);
  return (
    <AuthProvider>
      <Router />
      <div className="App">
        {/* <TradingViewWidget /> */}

        {/* <header className="App-header">
        <Router></Router>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        Learn React
        </a>
      </header> */}
      </div>
    </AuthProvider>
  );
}

export default App;
