import React, { useState } from "react";
import { Button } from "antd";
import { CenterCard } from "../components/CenterCard";
import { UserForm } from "./components/UpdateUserForm";
import { EditPasswordModal } from "./components/EditPasswordModal";

export const UpdateUserPage = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <CenterCard>
      <UserForm />
      <Button onClick={() => setShowModal(true)}>Change password</Button>
      {showModal && (
        <EditPasswordModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </CenterCard>
  );
};
