import { utcToZonedTime } from 'date-fns-tz'
export interface User {
  id: string;
  name: string;
  apiKey: string;
  // apiSecret: string;
  isAdmin: boolean;
  telegramChannel: string
}

type TokenExpired = boolean

const SERVER_TZ = "Europe/Berlin"

export const decodeJwt = (token: string | undefined): [User | undefined, TokenExpired] => {
  if (!token) {
    return undefined;
  }
  // Split the token into three parts.
  const parts = token.split(".");
  // Check if the token has three parts.
  if (parts.length !== 3) {
    throw new Error("Invalid JWT token");
  }

  // Decode the header and payload parts.
  const payload: {
    ApiKey: string;
    Id: string;
    IsAdmin: boolean;
    Name: string;
    Exp: number,
    TelegramChannel: string
  } = JSON.parse(atob(parts[1]));
  if (
    payload.ApiKey === undefined ||
    !payload.Id ||
    !payload.Name ||
    !payload.Exp ||
    payload.IsAdmin === undefined

  ) {
    console.log("issue with decoding payload", payload);
    return [undefined, true];
  }

  if (isTokenExpired(payload.Exp, new Date())) {
    console.log("not valid access token because of exparation date!")
    return [undefined, true];
  }

  // Return the decoded token.
  return [{
    isAdmin: payload.IsAdmin,
    apiKey: payload.ApiKey,
    // apiSecret: payload.ApiSecret,
    id: payload.Id,
    name: payload.Name,
    telegramChannel: "temp"

  }, false];
};


export function isTokenExpired(payloadExp: number, currentDate: Date): boolean {
  try {

    const expirationDate = new Date(payloadExp * 1000);
    const germanDate = utcToZonedTime(currentDate, SERVER_TZ)

    return germanDate > expirationDate;
  } catch (err) {
    console.log(err);
    return true;
  }
}