import React, { useState } from "react";
import { Table } from "antd";
import { DeleteUserButton } from "./DeleteUserButton";
export interface TableUser {
  name: string;
  id: string;
}

export interface AllUserTableProps {
  users: TableUser[];
}

// TODO: create a delete user button

const columns = [
  {
    title: "Id",
    key: "ID",
    dataIndex: "ID",
  },
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: 'Delete User',
    key: 'deleteUser',
    dataIndex: 'deleteUser',
    render: (text: string, record, index: number) => {

      return (
        <>
          <DeleteUserButton userId={record.ID} />
        </>
      );
    },
  },

];

export const AllUserTable: React.FunctionComponent<AllUserTableProps> = ({
  users,
}) => {

  return (
    <>

      <Table
        dataSource={users}
        columns={columns}
        pagination={false}
        rowKey={(user) => user.id}
      />{" "}
    </>
  );
};
