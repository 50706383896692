// GridForm.tsx
import React from "react";
import { Form, Input, Button, Switch, Card, Typography, Select } from "antd";

export interface StartGridValues {
  gridSize: number;
  upperBound: number;
  lowerBound: number;
  leverage: number;
  restartPercentage: number;
  strategy: string;
  closePosition?: boolean;
}

interface GridFormProps {
  onSubmit: (values: StartGridValues) => void;
  loading: boolean;
  disableButtons: boolean;
  setDisableButtons: React.Dispatch<React.SetStateAction<boolean>>;
  gridName: string;
  children?: React.ReactNode; // Add children prop for extensibility
}

const { Option } = Select;

export const GridForm: React.FC<GridFormProps> = ({
  onSubmit,
  loading,
  disableButtons,
  setDisableButtons,
  gridName,
  children,
}) => {
  const [gridForm] = Form.useForm<StartGridValues>();

  return (
    <Card>
      <Typography.Title level={3}>{gridName}</Typography.Title>
      <Form<StartGridValues>
        form={gridForm}
        onFinish={onSubmit}
        onFinishFailed={() => console.log("failed")}
        layout="vertical"
      >
        <Form.Item
          label="Grid size"
          name="gridSize"
          rules={[{ required: true, message: "Please input your grid size!" }]}
        >
          <Input placeholder="Enter your grid size" type="number" />
        </Form.Item>

        <Form.Item
          label="Leverage"
          name="leverage"
          initialValue={4}
          rules={[
            {
              required: true,
              message: "Please input the leverage amount (default value is 4):",
            },
          ]}
        >
          <Input placeholder="Enter your leverage" type="number" />
        </Form.Item>

        <Form.Item
          label="Upper percentage bound"
          name="upperBound"
          initialValue={1.1}
          rules={[{ required: false }]}
        >
          <Input
            disabled={disableButtons}
            placeholder="Enter your upper bound"
            type="number"
          />
        </Form.Item>

        <Form.Item
          label="Lower percentage bound"
          name="lowerBound"
          initialValue={0.8}
          rules={[{ required: false }]}
        >
          <Input
            disabled={disableButtons}
            placeholder="Enter your lower bound"
            type="number"
          />
        </Form.Item>

        <Form.Item
          label="Strategy"
          name="strategy"
          rules={[{ required: true, message: "Please select your strategy" }]}
        >
          <Select placeholder="Select a strategy">
            <Option value="long">Long</Option>
            <Option value="short">Short</Option>
            <Option value="flow">Flow</Option>
          </Select>
        </Form.Item>

        {/* Conditional Rendering of Close Position */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.strategy !== currentValues.strategy}
        >
          {({ getFieldValue }) =>
            getFieldValue("strategy") === "flow" ? (
              <Form.Item
                label="Close Position when trend is changing(grid type is changing')"
                name="closePosition"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item
          label="Restart percentage"
          name="restartPercentage"
          initialValue={1}
          rules={[{ required: false }]}
        >
          <Input
            disabled={disableButtons}
            placeholder="Enter the amount the grid should restart. Please type the percentage amount (1 would mean 1% above the grid)"
            type="number"
          />
        </Form.Item>

        <Form.Item
          name="switch"
          label="Create grid bound automatically"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch onClick={() => setDisableButtons(!disableButtons)} />
        </Form.Item>

        {children}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
