import React, { useState, useContext } from "react";
import { Button, message, Form, Tooltip } from "antd";
import { deleteUser } from "../../api/api";
import { UserContext } from "./AllUsersTab";

export interface DeleteUserButtonProps {
    userId: string
}
export const DeleteUserButton: React.FC<DeleteUserButtonProps> = ({ userId }) => {
    const [loading, setLoading] = useState(false);
    const { fetchUsers } = useContext(UserContext);
    const onClick = async () => {
        try {
            setLoading(true);
            const res = await deleteUser({ id: userId });
            if (res.status != 200) {
                message.error(res.data);
                setLoading(false);
                return res
            }
            message.success("Deleted user successfully");
            fetchUsers();
            setLoading(false);
        } catch (err) {
            console.log(err.message || err.statusText);
            message.error(
                "Can't delete user becuase of internal error, please contact admin"
            );

            setLoading(false);
        }
    };
    return (
        <>
            <Form.Item>
                <Tooltip title="Are you sure you want to delete this user?">
                    <Button type="primary" danger onClick={onClick} loading={loading}>
                        Delete User
                    </Button>
                </Tooltip>
            </Form.Item>
        </>
    );
};
