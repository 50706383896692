import { useState, useEffect } from "react";
import {
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";

import { useAuth, getToken } from "./AuthProvider";
import { decodeJwt } from "./authutils";

export const ProtectedRoute = ({ children }) => {
  let { user, getAccessToken } = useAuth()

  if (!user) {
    return <Navigate to={"/forbidden"} replace />;
  }

  return children ? children : <Outlet />;
};

export const AdminRoute = ({ children }) => {
  let { user, getAccessToken } = useAuth()

  if (user && user.isAdmin) {
    return children ? children : <Outlet />;
  }
  return <Navigate to={"/"} replace />;
};