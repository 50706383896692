import React, { createContext } from "react";
import { authReq, Login, LoginResponse, validateToken, generateAccessToken } from "../api/api";
import { decodeJwt, User } from "./authutils";
import { AxiosError } from "axios";
export type Maybe<T> = NonNullable<T> | null | undefined;
// https://blog.finiam.com/blog/predictable-react-authentication-with-the-context-api
// https://blog.devgenius.io/how-to-add-authentication-to-a-react-app-26865ecaca4b
const AuthContext = createContext(null);

interface AuthContextValues {

  user: User;
  validateBackend: (token: string) => Promise<boolean>;
  getAccessToken: () => Promise<User | null>;
  login: (data: Login) => Promise<boolean | AxiosError<unknown, any>>;
  onLogout: () => void;

}

const getUserFromToken = () => {
  const oldToken = getToken()
  if (!oldToken) {
    return null
  }
  const [user, _] = decodeJwt(oldToken);
  if (user) {
    return user;
  }
  return null
}
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(null);

  const handleLogin = async (data: Login) => {
    // TODO: add an error state
    let res = await authReq(data);
    if (res instanceof AxiosError) {
      return res;
    }
    if (res.status === 401) {
      return false;
    }
    const accessToken = await res.data;
    const [user, refetchToken] = decodeJwt(accessToken.token);
    // TODO: refetch token if true
    if (user) {
      setUser(user);
      storeToken(accessToken.token)
      return true;
    }

    return false;
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  // validate with the backend
  const handleBackendValidation = async (token: string) => {
    const tryValidation = await validateToken(token);
    if (tryValidation instanceof Error || !tryValidation.ok) {
      console.log("faild to validate existing token")
      localStorage.removeItem("token");
      return false;
    }
    return true;
  };

  const getAccessToken = async (): Promise<User | null> => {
    const oldToken = getToken()
    const [user, refetchToken] = decodeJwt(oldToken);
    if (user) {
      return user;
    }
    localStorage.removeItem("token");
    if (refetchToken) {
      const accessRes = await generateAccessToken();
      if (accessRes.ok) {
        const jsonToken = await accessRes.json();
        const newToken = jsonToken.token;
        storeToken(newToken)
        const user = getUserFromToken()
        setUser(user)
        return user
      } else {
        throw Error("Could not fetch new access token");
      }
    }
    return null;

  }


  const value: AuthContextValues = {
    user: getUserFromToken(),
    validateBackend: handleBackendValidation,
    getAccessToken: getAccessToken,
    login: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValues => {
  return React.useContext(AuthContext);
};

// INFO: deprecated
export const getToken = (): Maybe<string> => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      return token;
    } catch (err) {
      console.log("Could not parse token: ", err);
      localStorage.removeItem("token");
      return undefined;
    }
  }
  return undefined;
};

export const storeToken = (token: string) => {
  localStorage.removeItem("token");
  localStorage.setItem("token", token);
}


const getAccessToken = async () => {
  const oldToken = getToken()
  const [user, refetchToken] = decodeJwt(oldToken);
  if (user) {
    return true;
  }
  localStorage.removeItem("token");
  if (refetchToken) {
    const accessRes = await generateAccessToken();
    if (accessRes.ok) {
      const jsonToken = await accessRes.json();
      const newToken = jsonToken.token;
      storeToken(newToken)
    } else {
      throw Error("Could not fetch new access token");
    }
  }
  return false;

}
