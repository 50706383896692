import React, { useState } from "react";
import { message, Form, Button, Input } from "antd";
import { ReloadGridInput } from "../../api/api";
import { AxiosResponse } from "axios";
import { handleError } from "../../../utils";
import { GridForm } from "./GridForm"
import { StartGridValues } from "./StartGrid";
import { MarketPlace } from "../../../const";
import { useAuth } from "../../auth/AuthProvider";

export interface ReloadGridValues extends StartGridValues {
    marketPrice: number
    initialBalance: number
}

interface ReloadGridProps {
    reloadGridReq: (gridData: ReloadGridInput) => Promise<AxiosResponse<any, any>>;
    gridName: MarketPlace;
    reloadGrid?: boolean;
}

export const ReloadGrid: React.FunctionComponent<ReloadGridProps> = ({
    reloadGridReq,
    gridName,
}) => {
    const [loading, setLoading] = useState(false);
    const [disableButtons, setDisableButtons] = useState(true);
    let { user } = useAuth()
    const startGridCall = async (values: ReloadGridValues) => {
        setLoading(true);
        const data: ReloadGridInput = {
            gridSize: Number(values.gridSize),
            lowerBound: values.lowerBound && Number(values.lowerBound),
            upperBound: values.upperBound && Number(values.upperBound),
            leverage: values.leverage && Number(values.leverage),
            restartPercentage: values.restartPercentage && Number(values.restartPercentage),
            marketPrice: values.marketPrice && Number(values.marketPrice),
            initialBalance: values.initialBalance && Number(values.initialBalance),
            strategy: values.strategy,
            userId: user?.id.toString(),
            closePosition: values.closePosition

        };
        try {
            const res = await reloadGridReq(data);
            if (res.status === 200) {
                message.success(
                    "Starting grid, please look at the logs for information regarding the grid state"
                );
            } else {
                message.error(`Failed to start grid for unknown reason`);
            }
        } catch (error) {
            const errMsg = handleError(error.response?.data?.error);
            message.error(`Failed to start grid: ${errMsg}`);
        }
        setLoading(false);
    };

    return (
        <>
            <>

                <GridForm
                    onSubmit={startGridCall}
                    loading={loading}
                    disableButtons={disableButtons}
                    setDisableButtons={setDisableButtons}
                    gridName={gridName}
                >

                    <Form.Item
                        label="Previous Grid Market Price"
                        name="marketPrice"
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder="Enter the market place that is written in telegram"
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Initial Balance"
                        name="initialBalance"
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder="Enter the initial balance that is written in telegram"
                            type="number"
                        />
                    </Form.Item>

                </GridForm>

            </>
        </>
    );
};
