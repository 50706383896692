import React, { useState, useEffect } from "react";

import { CenterCard } from "../components/CenterCard";
import { ReloadGrid } from "./components/ReloadGrid";
import { TabType } from "../../layout/TabView";
import { TableView } from "../../layout/TabView";
import { reloadGrid } from "../api/api";
import { MarketPlace } from "../../const";

export const ReloadGridPage: React.FC = () => {
    const coin = "Coin";
    const contentObj = {
        [coin]: <ReloadGrid gridName={MarketPlace.Coin} reloadGridReq={reloadGrid} />,
    };
    const tabList: TabType = [
        { key: coin, tab: coin },
    ];
    return (
        <div>
            <TableView tabList={tabList} contentObj={contentObj} />
        </div>
    );
};
