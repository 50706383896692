import { Form, message, Modal, Input, Typography } from "antd";

export interface PasswordModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
  name: string;
}

export const PassowrdModal: React.FunctionComponent<PasswordModalProps> = ({
  showModal,
  setShowModal,
  password,
  name,
}) => {
  return (
    <Modal
      title={`${name} Password`}
      open={showModal}
      onOk={() => setShowModal(!showModal)}
    >
      <Typography.Title level={3}>User Password: </Typography.Title>
      <Typography.Title level={4}>{password}</Typography.Title>

      <Typography.Paragraph>
        Make sure to store the password in a safe place, as this is the only
        time you could view it
      </Typography.Paragraph>
    </Modal>
  );
};
