import { error } from "console";
import { getToken } from "../auth/AuthProvider";
import { User } from "../auth/authutils";
import jwtInterceoptor from "./interceptor";
import { AxiosResponse, AxiosError } from 'axios'
import { URL } from "../../const";
// type ApiResponse<T> = Promise<AxiosResponse<RegisterUserOutput>>
export interface UpdateUserInput {
  apiKey: string;
  apiSecret: string;
  telegramChannel?: string;
  // currentPassword?: string;
  // newPassword?: string;
}


export interface StartGridInput {
  gridSize: number;
  upperBound?: number;
  lowerBound?: number;
  leverage: number;
  restartPercentage: number
  strategy: string
  userId: string
  closePosition?: boolean
}

export interface StopGridInput {
  userId: string
}
// TODO: change this once the backend would fetch the market data
export interface ReloadGridInput {
  gridSize: number;
  upperBound?: number;
  lowerBound?: number;
  leverage: number;
  restartPercentage: number
  marketPrice: number;
  initialBalance: number
  strategy: string
  userId: string
  closePosition?: boolean

}


export enum GridEndpointsEnum {
  COIN = "/coinGrid",
  USDM = "/usdmGrid"
}


export interface ValidTokeninput {
  token: string;
}
export interface ValidTokenResponse extends ValidTokeninput { }

export interface UpdatePasswordInput {
  currentPassword: string;
  newPassword: string;
}
export interface Login {
  name: string;
  password: string;
}

export interface RegisterUserInput {
  name: string;
  apiKey?: string;
  apiSecret?: string;
  telegramChannel?: string;
}

export interface DeleteUserInput {
  id: string
}

export interface RegisterUserOutput {
  apiKey?: string
  apiSecret?: string
  password: string
  username: string
  userId: string
}
export interface Users {
  users: User[];
}

export interface LoginResponse {
  token: string;
}
// const URL = "http://172.28.1.2:8080/api";
// const URL = "http://130.61.52.50/api";
// console.log(process.env.REACT_APP_API_URL);
// const URL = process.env.REACT_APP_API_URL;
// const URL = "http://localhost:8080/api";
// const URL = "http://backend:8080/api";
const URL_SECURE = URL + "/secured";
// ----------------- Get ------------------

export const validateToken = async (token: string): Promise<Response | Error> => {
  const url = URL + "/validateToken";
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// ----------------- Post unauth ------------------
export const authReq = async (data: Login): Promise<AxiosResponse<any> | AxiosError> => {
  const url = URL + '/login';
  try {
    const response = await jwtInterceoptor.post(url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    return response;
  } catch (error) {
    console.log(error instanceof AxiosError, "the error! ")
    throw error;
  }
};



// ----------------- Get auth -------------------
export const getAllUsers = async (): Promise<AxiosResponse<{ users: User[] }>> => {
  return await generateSecureGetReq("/allUsers")
}

export const getUser = async (): Promise<AxiosResponse<{ user: User }>> => {
  return await generateSecureGetReq(`/user`)
}
// ----------------- Post auth ------------------

export const reloadGrid = async (gridInput: ReloadGridInput) => {
  return await generatePostReq('/reloadCoinGrid', gridInput)
} 

export const generateAccessToken = async () => {
  const url = URL + "/accessToken";
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        // "Access-Control-Allow-Origin": "*",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (data: UpdatePasswordInput) => {
  return await generatePostReq("/updatePassword", data);
};

export const updateUser = async (userData: UpdateUserInput) => {
  return await generatePostReq("/updateUser", userData);
};

// TODO: those functions are not needed, you could follow the smae logic as the stop grid
export const startUsdmGrid = async (gridData: StartGridInput) => {
  return await startGrid(GridEndpointsEnum.USDM, gridData);
};

export const startCoinGrid = async (gridData: StartGridInput) => {
  return await startGrid(GridEndpointsEnum.COIN, gridData);
};

export const startGrid = async (endpoint: GridEndpointsEnum, gridData: StartGridInput) => {
  return await generatePostReq(endpoint, gridData);

}


export const deleteUser = async (deleteUser: DeleteUserInput) => {
  return await generatePostReq("/deleteUser", deleteUser);

}


export const stopGrid = async (endpoint: GridEndpointsEnum, input: StopGridInput) => {
  const res = await generateDeleteReq(endpoint, input)
  return res;
};

export const checkIfTokenValid = async () => {
  return await generatePostReq2("/checkToken");
};

export const registerUser = async (params: RegisterUserInput): Promise<AxiosResponse<RegisterUserOutput>> => {
  return await generatePostReq("/register", params);
};


// TODO: find the way to add the full backend url

const generatePostReq2 = async (endpoint: string, data?: Object) => {
  const token = getToken();
  const url = URL_SECURE + endpoint;
  try {
    const response = await fetch(url, {
      method: "POST",
      body: data && JSON.stringify(data),
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: token,
        // "Access-Control-Allow-Origin": "*",
      },
    });
    const res = await response.json();
    return res;
  } catch (error) {
    throw error;
    console.log(error, "!!!!!");
    return error;
  }
};

// current post request with axios and middelware
const generatePostReq = async (
  endpoint: string,
  data?: Object
) => {
  try {
    const url = URL_SECURE + endpoint;

    const response = await jwtInterceoptor.post(url, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    return response;
  } catch (error) {
    console.log(error, "post error! ")
    throw error;
  }
}

const generateDeleteReq = async (
  endpoint: string,
  data?: Object
) => {
  try {
    const url = URL_SECURE + endpoint;

    const response = await jwtInterceoptor.delete(url, {
      data: data,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    return response;
  } catch (error) {
    console.log(error, "delete error! ")
    throw error;
  }
}


const generateSecureGetReq = async (
  endpoint: string,
) => {
  try {
    const url = URL_SECURE + endpoint;

    const response = await jwtInterceoptor.get(url, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });

    return response;
  } catch (error) {
    console.log(error, "post error! ")
    throw error;
  }
}



