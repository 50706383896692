import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Card } from "antd";
import { useNavigate } from "react-router-dom";

export const CenterCard = ({ children }) => {
  return (
    <>
      <Row
        align="middle"
        justify="space-around"
        style={{ minHeight: "100vh", background: "rgba(0, 0, 0, 0.45)" }}
      >
        <Col
          xs={24}
          sm={22}
          md={14}
          lg={12}
          xl={8}
          style={{ textAlign: "center", padding: 16 }}
        >
          <Card bordered={false} style={{ borderRadius: 4, padding: 32 }}>
            {children}
          </Card>
        </Col>
      </Row>
    </>
  );
};
