import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Modal, Col, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { EditPasswordForm } from "./EditPasswordButton";
import { updatePassword, UpdatePasswordInput } from "../../api/api";

interface UpdatePasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

export const EditPasswordModal = ({ showModal, setShowModal }) => {
  const [form] = Form.useForm<UpdatePasswordFormValues>();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal
        title="Update Password"
        open={showModal}
        onCancel={() => {
          setShowModal(!showModal);
        }}
        onOk={async () => {
          setLoading(true);
          const isValidated = await form.validateFields();
          const passwordInput: UpdatePasswordInput = {
            currentPassword: isValidated.currentPassword,
            newPassword: isValidated.newPassword,
          };
          try {
            const sendData = await updatePassword(passwordInput);
            console.log(sendData, "send data");
            if (sendData.status === 200) {
              message.success("Password updated successfully");
              setShowModal(!showModal);
            } else {
              const res = sendData.data;
              message.error("failed to update password. " + res);
            }

          } catch (error) {
            message.error("failed to update password. " + error.response?.data);
          }
          setLoading(false);
        }}
      >
        <Form form={form} layout="vertical">
          <EditPasswordForm />
        </Form>
      </Modal>
    </>
  );
};
