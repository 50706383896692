import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, message, Switch } from "antd";
import { RegisterUserInput, registerUser } from "../../api/api";
import { RegisterUserForm } from "./RegisterUserForm";
import { PassowrdModal } from "./PasswordModal";

const STATUS_CONFLICTED = 409
export interface RegisterUserFormValues extends RegisterUserInput {}

export const RegisterUserTab = () => {
  const [registerUserForm] = Form.useForm<RegisterUserFormValues>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, SetPassword] = useState<string | undefined>(undefined);
  const [name, setName] = useState("");

  useEffect(() => {
    if (password) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [password]);

  const registerUserHandler = async (values: RegisterUserFormValues) => {
    setLoading(true)
    try {
      const res = await registerUser(values);
      setLoading(false)
      if (res.status === STATUS_CONFLICTED) {
        message.error("Can't create user as the user name is already in the system")
        return
      }
      if (res.status === 500) {
        message.error("Can't create because of internal error, please contact the admin ")
        return
      }
      const data = res.data
      setName(values.name);
      SetPassword(data.password)
    } catch (error) {
      message.error("Can't create because of internal error, please open the development console for more information")
      setLoading(false)
      return
    } 

    return true;
  };
  return (
    <>
      {password && (
        <PassowrdModal
          name={name}
          password={password}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <Form<RegisterUserFormValues>
        form={registerUserForm}
        onFinish={registerUserHandler}
        onFinishFailed={(err) => console.log(err)}
        layout="vertical"
      >
        <RegisterUserForm />
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </>
  );
};
