import React, { useState } from "react";
import { Card, Tabs } from "antd";
import { useSearchParams } from "react-router-dom";

export type TabType = { tab: string; key: string }[];

// T try to make the enum generic
interface TabViewProps<T> {
  contentObj: { [any: string]: React.ReactElement };
  tabList: TabType;
}

export function TableView<T>(props: TabViewProps<T>) {
  const { tabList, contentObj } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const paramTable = searchParams.get("table");
  const [currentTable, setCurrentTable] = useState<string>(
    paramTable ? paramTable : tabList[0].key
  );

  if (tabList.length == 0) {
    return <div>Can't use table view with no tab values(developer error)</div>;
  }


  return (
    <Card>
      <Tabs
        activeKey={currentTable}
        onChange={(key) => {
          setCurrentTable(key);
          setSearchParams({ table: key });
        }}
      >
        {tabList.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tabs.TabPane {...item} tab={item.tab} key={item.key || index}>
            {contentObj[item.key]}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  );
}
