import React from 'react';
import { Form, Input, Button, Typography, message, Switch, Card } from "antd";
import { CenterCard } from '../../components/CenterCard';
interface GetUserCardProps {
    user: {
        name: string;
        apiKey: string;
        telegramChannel: string;
        isAdmin: boolean;
    }
}

export const GetUserCard: React.FC<GetUserCardProps> = ({ user }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: "0 -26px", height: '100vh', background: "rgba(0, 0, 0, 0.45)" }}>
            <div style={{ margin: "100px auto" }}>
                <Card title="User Information" >
                    <Typography.Title level={3}>Name: <Typography.Text>{user?.name}</Typography.Text></Typography.Title>
                    <Typography.Title level={3}>API Key: <Typography.Text>{user?.apiKey}</Typography.Text></Typography.Title>
                    <Typography.Title level={3}>Telegram Channel: <Typography.Text>{user?.telegramChannel}</Typography.Text></Typography.Title>
                </Card>
            </div>
        </div>
    );
}

