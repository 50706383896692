import React, { useState, useEffect } from "react";
import {
  Form,
  message,
  Modal,
  Input,
  FormInstance,
  Button,
  Switch,
} from "antd";
import { UpdateUserInput } from "../../api/api";
import { updateUser } from "../../api/api";
interface UserFormProps {
  userForm: FormInstance<UpdateUserInput>;
}

export const UserFormFields: React.FC = () => {

  return (
    <>
      <Form.Item
        label="API key"
        name="apiKey"
        rules={[{ required: true, message: "Please input your API key!" }]}
      >
        <Input placeholder="Enter your API key" />
      </Form.Item>

      <Form.Item
        label="API secret"
        name="apiSecret"
        rules={[{ required: true, message: "Please input your API secret!" }]}
      >
        <Input placeholder="Enter your API secret" />
      </Form.Item>

      <Form.Item label="Telegram channel" name="telegramChannel">
        <Input placeholder="Enter your Telegram channel(leave empty if you don't need to update it)" />
      </Form.Item>
    </>
  );
};

// TODO: create better error handling
export const UserForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userForm] = Form.useForm<UpdateUserInput>();

  const updateUserCall = async (e) => {
    setLoading(true);
    try {
      const isValidated = await userForm.validateFields();
      const res = await updateUser(isValidated);

      if (res.status === 200) {
        message.success("User updated successfully");
      } else {
        // @ts-ignore
        message.error(`failed to add user: ${res?.error}`);
      }
    } catch (error) {
      message.error("failed to update user: " + error.response.data.error.body);
    }
    setLoading(false);
  };

  return (
    <Form<UpdateUserInput>
      form={userForm}
      onFinishFailed={() => console.log("failed")}
      layout="vertical"
    >
      <UserFormFields />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={updateUserCall}
        >
          Update User
        </Button>
      </Form.Item>
    </Form>
  );
};
