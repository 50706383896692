import axios from "axios";
import { URL } from "../../const";
export type Maybe<T> = NonNullable<T> | null | undefined;

const jwtInterceoptor = axios.create({});


export const getToken = (): Maybe<string> => {
    const token = localStorage.getItem("token");
    if (token) {
        return token
    }
    localStorage.removeItem("token");
    return undefined;
};

export const storeToken = (token: string) => {
    localStorage.removeItem("token");
    localStorage.setItem("token", token);
}


jwtInterceoptor.interceptors.request.use((config) => {
    let tokensData = getToken();
    if (tokensData) {
        config.headers["Authorization"] = `${tokensData}`;
    }
    return config;
});

jwtInterceoptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error?.response?.status === 401 && document.cookie.includes("token")) {
            const path = URL + '/accessToken'
            let apiResponse = await axios.post(
                path, {
                withCredentials: true,
            }
            );
            if (apiResponse?.data) {
                storeToken(apiResponse.data)
                localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
                error.config.headers[
                    "Authorization"
                ] = `${apiResponse.data.access_token}`;
                return axios(error.config);
            }
            else {
                Promise.reject(apiResponse)
            }
        }
        return Promise.reject(error);
    }
);
export default jwtInterceoptor;